import React, { useState } from "react";
import { app, appInitialization, authentication } from "@microsoft/teams-js";
import { AuthError, AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";
import { ApplicationTitle, ErrorStatusMessage, FiltersMetadata, GetWarningBanner, MsalObj, UserProfile, getApplicationTitle, getMetadataFilters, getMsalData, getQuestionExamples, getUserProfile, getWarningBanner } from "../../api";
import { Loader } from "../../components/Loader/Loader";
import { ThemeProvider, initializeIcons } from "@fluentui/react";
import { customTheme } from "../../theme/custom";
import { useTranslation } from "react-i18next";
import Layout from "../layout/Layout";
import SignIn from "../auth/SignIn";
import i18n from "../../i18n";

export enum EHostType {
    Teams = "Teams",
    Web = "Web",
}

export enum EUserRoles {
    User = "User",
    Admin = "Admin",
}

const App = () => {
    initializeIcons();
    
    const { t } = useTranslation();
    const [hostType, setHostType] = useState<EHostType>();
    const [loadingApp, setLoadingApp] = useState(true);
    const [isAppReady, setIsAppReady] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [user, setUser] = useState<UserProfile>();
    const [loadingUser, setLoadingUser] = useState(false);
    const [infoData, setWarningBanner] = useState<GetWarningBanner | null>(null);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [title, setTitle] = useState<ApplicationTitle | null>(null);
    const [loadingTitle, setLoadingTitle] = useState(false);
    const [metadataFilters, setMetadataFilters] = useState<any>();
    const [loadingMetadataFilters, setLoadingMetadataFilters] = useState(false);
    const [questionExamples, setQuestionExamples] = useState<any>();
    const [loadingQuestionExamples, setLoadingQuestionExamples] = useState(false);

    const [error, setError] = useState<number>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const rootPath = window.location.pathname === "/";

    const apiLoaders = (loading: boolean) => {
        setLoadingUser(loading);
        setLoadingInfo(loading);
        setLoadingTitle(loading);
        setLoadingMetadataFilters(loading);
        setLoadingQuestionExamples(loading);
    }

    const apiCalls = async () => {
        try {
            const userProfileData = await getUserProfileData();

            if (userProfileData.status && userProfileData.status > 299) {
                return;
            }

            // Proceed only if getUserProfileData() succeeds
            await Promise.all([
                getMetadataFiltersData(),
                getQuestionExamplesData()
            ]).then(res => setLoadingApp(false));
            // Proceed with your logic here using userProfileData, metadataFiltersData, and questionExamplesData
        } catch (error) {
            // Handle error appropriately
            console.error("Error occurred while fetching user profile data:", error);
            // Optionally, you can set loading state here
            setLoadingApp(false);
        }
    }

    const teamsAuthTokenRequest = (tenantId: string | undefined) => {
        return {
            silent: true,
            tenantId: tenantId,
            successCallback: async (token: string) => {
                setIsAuth(true);
                apiLoaders(true);

                apiCalls();
            },
            failureCallback: (error: string) => {
                console.log(`Error when authenticating on Teams: ${error}`);
                setLoadingApp(false);
                apiLoaders(false);
                setIsAuth(false);
            },
        }
    };

    const webAuthTokenRequest = async () => {
        void getMsalData().then(async (res: MsalObj) => {
            const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
            const msalObj = {
                auth: {
                    clientId: res.clientId,
                    authority: res.authority,
                    redirectUri: window.location.origin,
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE,
                }
            }

            const msalInstance = await PublicClientApplication.createPublicClientApplication(msalObj);
            const accounts = msalInstance.getAllAccounts();

            if (accounts?.length > 0) {
                msalInstance.acquireTokenSilent({ scopes: res.scopes.split(" "), account: accounts[0] })
                    .then(async (response: AuthenticationResult) => {
                        setIsAuth(true);
                        apiLoaders(true);

                        apiCalls();
                    }).catch((err: AuthError) => {
                        setLoadingApp(false);
                        apiLoaders(false);
                        setIsAuth(false);
                        console.log(`Error on acquire token in web. Details: ${err}`);
                    })
            } else {
                setLoadingApp(false);
                apiLoaders(false);
                setIsAuth(false);
            }
        });
    }

    const getUserProfileData = async (): Promise<UserProfile> => {
        try {
            const res = await getUserProfile(hostType!);
            if (res.status) {
                switch (res.status) {
                    case 401:
                        setIsAuth(false);
                        break;
                    case 403:
                        setError(res.status);
                        setErrorMsg(ErrorStatusMessage.Forbidden);
                        break;
                    default:
                        setError(res.status);
                        setErrorMsg(res.code);
                        break;
                }
    
                setLoadingUser(false);
                throw new Error(`Error status received: ${res.status}`);
            }
    
            i18n.changeLanguage(res.ui_language ?? "pt-BR");
            setUser(res);
            setLoadingUser(false);
            return res;
        } catch (error) {
            console.error("Error when obtaining user profile:", error);
            throw error; // Re-throw the error to propagate it to the caller
        }
    }

    const getInfoData = async () => {
        try {
            return await getWarningBanner(hostType!).then((res: GetWarningBanner) => {
                if (res.status) { // response.status > 299 in api response.
                    switch (res.status) {
                        case 401:
                            setIsAuth(false);
                            break;
                        default:
                            setError(res.status);
                            setErrorMsg(res.code);
                            break;
                    }

                    setLoadingUser(false);
                    return;
                }

                setWarningBanner(!res.WARNING_BANNER_TEXT ? null : res);
                setLoadingInfo(false);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const getTitleData = async () => {
        try {
            return await getApplicationTitle(hostType!).then((res: ApplicationTitle) => {
                if (res.status) { // response.status > 299 in api response.
                    switch (res.status) {
                        case 401:
                            setIsAuth(false);
                            break;
                        default:
                            setError(res.status);
                            setErrorMsg(res.code);
                            break;
                    }

                    setLoadingTitle(false);
                    return;
                }

                setTitle(res);
                setLoadingTitle(false);
            }).catch((err) => {
                console.log(`Error when obtaining user profile. ${err}`);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const getMetadataFiltersData = async () => {
        try {
            return await getMetadataFilters(hostType!).then((res: FiltersMetadata) => {
                if (res.status) { // response.status > 299 in api response.
                    switch (res.status) {
                        case 401:
                            setIsAuth(false);
                            break;
                        default:
                            setError(res.status);
                            setErrorMsg(res.code);
                            break;
                    }

                    setLoadingMetadataFilters(false);
                    return;
                }
                
                setMetadataFilters(JSON.stringify(res) === '{}' ? undefined : res);
                setLoadingMetadataFilters(false);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const getQuestionExamplesData = async () => {
        try {
            return await getQuestionExamples(hostType!).then((res: any) => {
                if (res.status) { // response.status > 299 in api response.
                    switch (res.status) {
                        case 401:
                            setIsAuth(false);
                            break;
                        default:
                            setError(res.status);
                            setErrorMsg(res.code);
                            break;
                    }

                    setLoadingQuestionExamples(false);
                    return;
                }

                setQuestionExamples(JSON.stringify(res) === '{}' ? undefined : res);
                setLoadingQuestionExamples(false);
            });
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        app.initialize()
        .then(() => {
            app.notifySuccess();
            app.notifyAppLoaded();
            setHostType(EHostType.Teams);
        }).catch(() => {
            setHostType(EHostType.Web);
        })
    }, []);
      
    React.useEffect(() => {
        if (hostType === EHostType.Teams) {
            setIsAppReady(true);
            app.getContext().then((context) => {
                authentication.getAuthToken(teamsAuthTokenRequest(context.user?.tenant?.id));
                setHostType(EHostType.Teams);
            });
        }

        if (hostType === EHostType.Web) {
            setIsAppReady(true);
            webAuthTokenRequest();
        }
    }, [hostType]);

    return (
        <ThemeProvider theme={customTheme} style={{ height: '100%' }}>
            {((loadingUser || loadingInfo || loadingTitle || loadingMetadataFilters || loadingQuestionExamples) && loadingApp && rootPath) && 
                <Loader hostType={hostType!} allowControls={user?.roles.toLowerCase().includes(EUserRoles.Admin.toLowerCase())} t={t} />
            }

            {!loadingApp &&
                <>
                    {(isAppReady && !isAuth && rootPath) && <SignIn hostType={hostType!} />}
                    {(isAppReady && isAuth) && 
                            <Layout 
                                hostType={hostType!} 
                                allowControls={user?.roles.toLowerCase().includes(EUserRoles.Admin.toLowerCase())} 
                                infoData={infoData}
                                title={title}
                                error={error}
                                errorMsg={errorMsg}
                                user={user}
                                filtersMetadata={metadataFilters}
                                questionExamples={questionExamples}
                            />
                    }
                </>
            }
        </ThemeProvider>
    );
}

export default App;