import React, { useState, useEffect } from 'react';
import { getCitationFilePath } from '../../api';
import { getPdfPath } from '../../helpers/string-helper';
import { Callout, DirectionalHint } from '@fluentui/react';
import styles from "./TypingEffect.module.css";

interface TypingEffectProps {
    sanitizedAnswerHtml: string;
    onCitationClicked: (citationFilePath: string, sourcePath: string, pageNumber: string) => void;
    onTypingEnd: () => void;
    onSetMetadata: (metadata: any) => void;
    calloutRender: any;
}

const TypingEffect: React.FC<TypingEffectProps> = ({ sanitizedAnswerHtml, onCitationClicked, onTypingEnd, onSetMetadata, calloutRender }) => {
    const [displayedHtml, setDisplayedHtml] = useState<string>('');
    const [hoverTargetElement, setHoverTargetElement] = useState();
    const [calloutRenderState, setCalloutRender] = useState();
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
    const [typingEnd, setTypingEnd] = useState<boolean>(false);
    const [metadata, setMetadata] = useState<any>();

    useEffect(() => {
        let index = 0;
        const interval = setInterval(() => {
            const nextChar = sanitizedAnswerHtml.charAt(index);
            
            // Check if the next character is the start of a tag
            if (nextChar === '<') {
                const nextTagEndIndex = sanitizedAnswerHtml.indexOf('>', index); // Find the end of the tag
                const tag = sanitizedAnswerHtml.substring(index, nextTagEndIndex + 1);
                setDisplayedHtml(prevHtml => prevHtml + tag);
                index = nextTagEndIndex + 1;
            } else {
                // Otherwise, add the next character to the displayed HTML
                setDisplayedHtml(prevHtml => prevHtml + nextChar);
                index++;
            }
    
            if (index >= sanitizedAnswerHtml.length) {
                clearInterval(interval); // Stop interval when all HTML has been displayed
                onTypingEnd();
                setTypingEnd(true);
            }
        }, 1); // Adjust typing speed as needed
    
        return () => clearInterval(interval); // Clear interval when component unmounts
    }, [sanitizedAnswerHtml]);

    useEffect(() => {
        if (!!metadata) {
            onSetMetadata(metadata);
        }
    }, [metadata]);

    useEffect(() => {
        calloutRender && setCalloutRender(calloutRender);
    }, [calloutRender]);

    return (
        <>
            <div
                className={`${styles.answerText} ${styles.typingText}`}
                dangerouslySetInnerHTML={{ __html: displayedHtml }}
                onClick={(e: any) => {
                    const targetElement = e.target.parentElement.dataset;
                    const path = decodeURIComponent(decodeURI(targetElement.path)).split("/").slice(4).join("/");
                    
                    if (e.target.className?.includes('citation') || e.target.localName?.toLowerCase().includes('sup'))
                        onCitationClicked(getCitationFilePath(path), (targetElement.sourcepath as any), (targetElement.pagenumber as any));
                }}
                onMouseOver={(e: any) => {
                    setHoverTargetElement(undefined);
                    setCalloutRender(undefined);
                    const targetElement = e.target.parentElement.dataset;
                    const path = decodeURIComponent(decodeURI(targetElement.path)).split("/").slice(4).join("/");

                    if (e.target.className?.includes('citation') || e.target.localName?.toLowerCase().includes('sup') && typingEnd) {
                        setHoverTargetElement(e.target);
                        setCalloutVisible(true);
                        setMetadata({
                            path: getPdfPath(path),
                            page: targetElement.pagenumber,
                            dataPublicacao: targetElement.dtpublicacao,
                            dataJulgamento: targetElement.dtjulgamento,
                            tribunal: targetElement.tribunal,
                            isprecedente: targetElement.isprecedente,
                            tema: targetElement.tema,
                            processoTitulo: targetElement.processotitulo,
                        });
                    }
                }}
            />
            <Callout
                target={hoverTargetElement}
                hidden={!calloutVisible}
                directionalHint={DirectionalHint.bottomCenter}
                onDismiss={() => setCalloutVisible(false)}
                onMouseLeave={() => setCalloutVisible(false)}
            >
                {calloutRenderState}
            </Callout>
        </>
    );
};

export default TypingEffect;